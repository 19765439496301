import React from "react";
import { sendTagOnContactCall } from "app/utils/analytics";
import "./header.scss";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Sticky } from "react-sticky";
import { isProductPage } from "app/utils/urlDataExtractor";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import get from "lodash/get";
import IconPhone from "app/pages/.shared/static/icons/IconPhoneBordered";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";

const Header = ({
	dialPhone,
	match,
	merchandisingCode,
	shop,
	fallbackRedirectUrl,
	useBrowserBackOnProductPage,
}) => {
	const isFicheProduitPage = isProductPage(match.path);

	const language = shop.slice(0, 2);

	// div > header + nav au lieu de header > nav pour que nav passe en dessous
	const subMenuLinks = [
		{
			id: "submenu.home",
			link: `https://www.my-store.ch/${language}/`,
		},
		{
			id: "submenu.your.town",
			link: `https://www.my-store.ch/${language}/city/`,
		},
		{
			id: "submenu.travelandflights",
			link: fallbackRedirectUrl,
		},
		{
			id: "submenu.fashion",
			link: `https://www.my-store.ch/${language}/fashion/`,
		},
		{
			id: "submenu.cosmetics",
			link: `https://www.my-store.ch/${language}/cosmetics/`,
		},
		{
			id: "submenu.living",
			link: `https://www.my-store.ch/${language}/home-living/`,
		},
		{
			id: "submenu.sport",
			link: `https://www.my-store.ch/${language}/sport/`,
		},
		{
			id: "submenu.wine.gourmet",
			link: `https://www.my-store.ch/${language}/gourmet/`,
		},
	];
	return (
		<header className="header">
			<section className="header__content">
				<div className="header__back">
					{isFicheProduitPage && (
						<BackButton
							forceBrowserBack={useBrowserBackOnProductPage}
							to={
								merchandisingCode
									? {
											pathname: "/merch",
											search: `?code=${merchandisingCode}`,
											merchCode: merchandisingCode,
									  }
									: {
											pathname: fallbackRedirectUrl,
									  }
							}
							label=""
						/>
					)}
				</div>
				<div className="header__brand">
					<a className="relative-link" href={fallbackRedirectUrl}>
						<LogoBrandContainer />
					</a>
				</div>
				<div className="header__phone">
					<a
						className="header__phone-button"
						onClick={sendTagOnContactCall}
						href={`tel:${dialPhone}`}
					>
						<IconPhone height={30} width={30} />
					</a>
				</div>
			</section>
			<Sticky
				style={{
					/* stylelint-disable */
					transform: "none",
					background: "#eff0f4",
					boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 5px 0px",
				}}
				stickyClassName="header__sticky-container--sticky"
			>
				<section className="header__submenu">
					{isFicheProduitPage && (
						<div className="header__submenu__back">
							<BackButton
								to={
									merchandisingCode
										? {
												pathname: "/merch",
												search: `?code=${merchandisingCode}`,
												merchCode: merchandisingCode,
										  }
										: {
												pathname: fallbackRedirectUrl,
										  }
								}
								label={
									merchandisingCode ? "general.back" : "general.back.all.sales"
								}
							/>
						</div>
					)}
					<div className="header__submenu__scroll-menu">
						{subMenuLinks.map(item => (
							<a
								className={classNames("header__submenu__scroll-menu__item", {
									"header__submenu__scroll-menu__item--selected":
										item.id === "submenu.travelandflights",
								})}
								key={item.id}
								href={item.link}
							>
								<FormattedMessage id={item.id} />
							</a>
						))}
					</div>
				</section>
			</Sticky>
			{isFicheProduitPage && (
				<section className="header-navigation">
					<BackButton
						to={
							merchandisingCode
								? {
										pathname: "/merch",
										search: `?code=${merchandisingCode}`,
										merchCode: merchandisingCode,
								  }
								: {
										pathname: fallbackRedirectUrl,
								  }
						}
						label={merchandisingCode ? "general.back" : "general.back.all.sales"}
					/>
				</section>
			)}
		</header>
	);
};

Header.propTypes = {
	dialPhone: PropTypes.string,
	match: PropTypes.object,
	merchandisingCode: PropTypes.string,
	shop: PropTypes.string,
	fallbackRedirectUrl: PropTypes.string,
	useBrowserBackOnProductPage: PropTypes.bool,
};

Header.defaultProps = {
	match: {},
};

const mapStateToProps = state => {
	return {
		merchandisingCode: get(state, "merchandising.current.code"),
		shop: state.shop,
	};
};

export default connect(mapStateToProps)(Header);
